.create-btn .ant-btn-default {
    width: 100px !important;
    margin-bottom: 8px !important;
    float: right;
    margin-right: 2rem;
    /* margin-top: 10px; */
    color: #0e4d92 !important;
    background-color: white !important;
    border-radius: 15px !important;
    box-shadow: 0.5px 0.5px 1px 1px rgb(186 186 186);
}

.custom-btn {
    color: #0e4d92 !important;
    background-color: white !important;
    border-radius: 15px !important;
    box-shadow: 0.5px 0.5px 1px 1px rgb(186 186 186);
    padding: 3px 10px;
}

.user-table-div .ant-table {
    position: relative !important;
}

.user-table-div .ant-table-wrapper {
    overflow: hidden;
}

.user-table-div .ant-table-content{
    width: 100% !important;
}

.user-top-bar{
    justify-content: end !important;
    position:relative;
}

.user-div {
    padding-left: 4% !important;
    padding-right: 4%;
}

.user-modal-form .ant-modal-content {
    width: 10% !important;
}

.user-table-footer {
    display: flex;
    justify-content: center;
}

.user-table-footer .ant-pagination {
    margin-top: 20px;
}

.user-table-footer {
    display: flex;
    justify-content: start;
    margin-left: 90px;
}

.user-real-table table {
    /* border-right: 1px solid #0e4d92 !important;
    border-bottom: 1px solid #0e4d92 !important;
    border-left: 1px solid #0e4d92 !important; */
}

.user-real-table table thead tr th {
    /* border-top: 1px solid #0e4d92; */
    position: sticky;
    top: 0;
    z-index: 1;
}

.user-table-div .ant-table-content {
    /* border: 1px solid #0e4d92 !important; */
}

.user-div .ant-switch-checked {
    background-color: #0e4d92 !important;
}

/* By Akram Shekh */

.ant-table-content th.ant-table-cell {
    background-color: #EDEAEA !important;
    font-weight: 700;
    color: #0e4d92 !important;
    text-align: center;
}

tbody.ant-table-tbody .ant-table-cell {
    /* background-color: #fff !important; */
}

.user-table-div{
    box-shadow: rgba(0, 0, 0, 0.25) -20px 0px 20px -20px !important;
    height: calc(65vh) !important;
}

.user-div .ant-table-thead > tr > th{
    padding: 0px !important;
}

.user-real-table table tbody tr{
    background-color: white !important;
}

.user-real-table table tbody tr:hover td{
    background-color: #EDEAEA !important;
}