.ant-modal-body {
  height: 440px !important;
  overflow: scroll;
  padding: 20px 10px 10px 20px !important;
}

.ant-modal-content .ant-modal-header{
  background: aliceblue !important;
}

.ant-row {
  display: block !important;
}

/* .ant-modal {
  width: 70% !important;
} */

.ant-select {
  /* width: 200px !important; */
}

.form-group .ant-form-item {
  margin-left: 20px !important;
}

.form-group .ant-form-item:nth-child(1) {
  margin-left: 0px !important;
}

.ant-form-item-control-input-content {
  /* width: 200px !important; */
}

textarea.ant-input {
  /* width: 200px !important; */
}

.ant-row {
  /* width: 200px !important; */
}

.ant-input {
  /* width: 300px !important; */
}

.form-group .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
  box-shadow: none !important;
}

.form-group .ant-input:hover {
  border-color: black !important;
  box-shadow: none !important;
}

.form-group .ant-input:focus {
  box-shadow: none !important;
}

.form-group .ant-select:hover .ant-select-selector {
  border-color: black !important;
  box-shadow: none !important;
}
.form-group .ant-input:focus {
  border-color: black !important ;
  box-shadow: none !important;
}

.form-group .ant-upload.ant-upload-select-picture-card:hover {
  border-color: black !important;
}

.upload-div .ant-btn-default {
  margin-left: 0px !important;
  width: 100px !important;
  padding: 0px 5px !important;
}

.edit-delete-btn{
  border: none !important;
  color:rgb(14 77 146) !important;
  background-color: transparent !important;
  margin: 0px !important;
  padding: 0px !important;
  box-shadow: none !important;
  width: inherit !important;
}

.add-notes-btn{
  border: none !important;
  color:rgb(14 77 146) !important;
  background-color: transparent !important;
  margin: 0px !important;
  padding: 0px !important;
  box-shadow: none !important;
  width: inherit !important;
}

/* .add-notes-btn:hover{
  background-color: ;
} */

.form-row {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between ;
}

/* .note-group{
  height: 70px !important;
  overflow-y: hidden !important;
} */
.form-group .input-field{
  background-color: #F2F2F2 !important;
  border-radius: 8px !important;
  /* border-top: none !important;
  border-right: none !important;
  border-left: none !important; */

}
.form-group .ant-form-item-control-input{
  width: 250px !important;
  border-radius: 8px !important;

}
.form-group .ant-select-selector{
  background-color: #F2F2F2 !important;
  border-radius: 8px !important;
  /*border-top: none !important;
  border-right: none !important;
  border-left: none !important; */
}
.cForm label{
  color: #043c79 !important;
}

.btn-group{
  display: flex !important;
  justify-content: right ;
  margin-top: 10px;
}

.form-btn-group .ant-form-item-control-input-content{
  display: flex !important;
  justify-content: center !important;
}

.cContactForm label{
  color: #043c79 !important;
}

.required:after {
  content:" *";
  color: red;
}

.note-group .textarea{
  resize: none;
}

.upload-div input{
  width: 100% !important;
}