.client-th-tr {
  background-color: #EDEAEA;
  color: black;
  position: sticky;
  top: 0;
  z-index: 2;
}

.client-th-tr th {
  font-size: 15px !important;
  font-weight: 500;
  padding-left: 10px !important;
  white-space: nowrap;
  position: relative;
}

.client-th-tr th::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 1.6em;
  background-color: rgba(0, 0, 0, 0.06);
  top: 10px;
  right: 0px;
}

.filter-icon {
  font-size: 13px;
  /* float: right; */
  padding-left: 5px;
  /* margin-top: 3px; */
  cursor: pointer;
}

.filter-icon svg {
  margin-top: -5px;
}

.client-body-tr:nth-child(even) {
  background-color: #FFFFFF;
}

.client-body-tr:nth-child(odd) {
  background-color: white;
}

.client-body-tr:nth-child(even):hover {
  background-color: #E6EDF6 !important;
}

.client-body-tr:nth-child(odd):hover {
  background-color: #E6EDF6 !important;
}

.client-body-tr td {
  color: #0e4d92 !important;
  font-size: 14px;
  padding: 10px !important;
  white-space: nowrap;
}

.client-table-md {
  overflow: scroll !important;
  /* border: 1px solid #0e4d92; */
}

.table-responsive {
  width: 100% !important;
  overflow: scroll !important;
  /* max-height: 500px !important; */
  height: calc(65vh) !important;
  box-shadow: rgba(0, 0, 0, 0.25) -20px 0px 20px -20px !important;
}

.client-body-tr td:nth-child(1) {
  align-items: center;
}

.client-body-tr td:nth-child(1) span {
  margin-right: 10px;
}

.minus-icon-md {
  font-size: 15px;
}

.plus-icon-md {
  font-size: 15px;
}

.add-client-modal {
  width: 25% !important;
}

.add-client-modal .form-group {
  display: block !important;
}

.add-client-modal .form-group {
  margin-bottom: 0px !important;
}

.add-client-modal .ant-form-item {
  margin-bottom: 8px !important;
}

.add-client-modal .ant-form-item-label label {
  margin-bottom: 0px !important;
}

.contact-modal-div {
  width: 25% !important;
}

.contact-modal-div .ant-modal-title {
  text-align: center;
}

.contact-modal-div .form-group {
  display: block !important;
}

.contact-modal-div .form-group {
  margin-bottom: 0px !important;
}

.contact-modal-div .ant-form-item {
  margin-bottom: 8px !important;
}

.contact-modal-div .ant-form-item-label label {
  margin-bottom: 0px !important;
}

.add-client-modal .ant-modal-title {
  text-align: center;
}

.add-client-modal .ant-input {
  text-align: left;
}

.contact-modal-div .ant-input {
  text-align: left;
}

.table-client-filter .ant-select-selection-search-input {
  text-align: left;
}

.importClient .ant-modal-title {
  text-align: center;
}

.global-antd-btn {
  background-color: #0e4d92 !important;
  color: white !important;
  border-radius: 15px !important;
}

.global-antd-btn:disabled{
  pointer-events: all !important;
}

.import-submit-btn {
  margin-top: 10px !important;
}

.draganddrop {
  width: 350px;
  height: 200px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%230E4D92FF' stroke-width='3' stroke-dasharray='8%2c10' stroke-dashoffset='16' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 15px;
  position: relative;
}

.draganddrop .draganddropinner {
  opacity: 0;
  height: 198px;
}

.draganddrop label {
  color: black;
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  position: absolute;
  top: 30px;
  left: 55px;
}

.draganddrop label i {
  color: #0e4d92;
}

.draganddrop label span {
  color: rgba(128, 128, 128, 0.795);
  font-size: 16px;
}

.error-div {
  margin-top: 25px;
  height: 120px;
  width: 300px;
  overflow-y: scroll;
  border: 1px solid #0e4d92;
}

.error-div p {
  color: red;
}

.clear-btn-filter {
  float: right;
  margin-top: 10px;
}

.sorticon-client-table {
  margin-left: 10px;
}

.inner-table-client thead th {
  /* position: sticky !important;
    top: 40px !important; */
  z-index: 1;
  background-color: #d5e9ff;
  color: black;
}

.inner-table-client {
  border: 1px solid #0e4d92;
}

.inner-table-client tbody tr td {
  color: #0e4d92;
}

.inner-table-client tbody tr:nth-child(even) {
  background-color: #d5e9ff;
}

.inner-table-client tbody tr:nth-child(odd) {
  background-color: white;
}

.inner-table-client tbody tr:nth-child(even):hover {
  background-color: #d5e9ff !important;
}

.inner-table-client tbody tr:nth-child(odd):hover {
  background-color: white !important;
}

/*---------Export Modal---------- */
.export-checkbox .selectAll-box label {
  font-size: 15px;
  margin-right: 5px;
}


.exp-btn button {
  margin-top: 15px !important;
}

.clientDeletebtn .ant-modal-body {
  height: 180px !important;
  overflow: hidden !important;
}

.clientDeletebtn .ant-modal-body .ant-modal-confirm-body svg {
  color: red !important;
}

.clientDeletebtn .ant-modal-confirm-body-wrapper {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
}

.clientDeletebtn .ant-modal-body .ant-modal-confirm-btns {
  text-align: center !important;
}

.clientDeletebtn .ant-modal-body .ant-modal-confirm-btns button {
  border-radius: 7px !important;
  margin: 0px !important;
}

.clientDeletebtn .ant-modal-body .ant-modal-confirm-btns .ant-btn-primary {
  background-color: #0e4d92 !important;
  /* background-color: #0e4d92 !important; */
  border-color: transparent !important;
  margin-left: 15px !important;
}


.exp-btn button {
  margin-top: 15px !important;
}

.client-th-tr th:last-child {
  position: sticky !important;
  right: 0 !important;
  background-color: #EDEAEA !important;
  text-align: center;
}

.client-body-tr td:last-child {
  position: sticky !important;
  right: 0px !important;
  /* border-left: 1px solid #d5e9ff !important; */
}

/* .client-body-tr td:last-child .edit-btn{
  border-left: 1px solid grey !important;
  border-radius: 0px !important;

} */

.client-body-tr td:last-child button:not(.add-notes-btn):hover {
  border: 0px solid #d2d2d2 !important;
  background-color: #0e4d92 !important;
  color: white !important;
}

.client-body-tr:nth-child(odd) td:last-child {
  background-color: #EDEAEA !important;
}

.client-body-tr:nth-child(even) td:last-child {
  background-color: #EDEAEA !important;
}


.download_template {
  text-align: center;
}

.download_template a {
  width: auto !important;
  color: #0e4d92 !important;
  padding: 10px 10px !important;

}

.export-modal {
  width: 25% !important;
}

.export-modal .selectAll-box {
  margin-bottom: 15px !important;
  font-weight: 600 !important;
}

.table-responsive table tr td{
  vertical-align: middle !important;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.table-sm td{
  padding: 10px !important;
}

.ant-popover-title{
  font-weight: 800 !important;
  background-color: aliceblue !important;
}

/* AHS */

/* table.table thead th::before {
  position: absolute;
  width: 1px;
  height: 1.6em;
  background-color: rgb(170, 162, 162);
  content: "";
  right: 1;
}

table.table thead th {
      padding-left: 10px !important;
}

table.table thead th:last-child::before, table.table thead th:first-child::before {
  position: absolute;
  width: 0px;
  height: 0px;
  content: "";
} */

.reset_btn_div button{
    position: relative !important;
    border: 1px solid #d5e9ff;
    margin-right: 10px !important;
    box-shadow: 0.5px 0.5px 1px 1px rgb(186 186 186);
    border-radius: 18px !important;
    padding: 5px 20px 5px 20px;
    color: #0e4d92;
    display: block;
}

.preview-table .preview-header b{
  font-weight: 800 !important;
}