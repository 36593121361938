.client_modal{
    width: 25% !important;
}

.client_modal .form-group{
    display: block !important;
}

.column_hide_show{
    margin-top: 20px;
}

.hide-column{
    width: 40% !important;
}

.hide-column .ant-modal-body{
    padding-top: 10px !important;
    padding-bottom: 0px !important;
}

.hide-column .select-all{
    margin-bottom: 15px !important;
    font-weight: 600 !important;
}

.characters{
    height: 77%!important;
    overflow: scroll !important;
}

    