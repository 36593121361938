.edit-delete-btn:hover {
  border: none !important;
  background-color: #0e4d92 !important;
}

.edit-delete-btn:hover svg {
  color: white !important;
}

.edit_contact {
  width: 25% !important;
}

.edit_contact input {
  text-align: left !important;
}

.edit_contact .form-group {
  display: block !important;
}

.template_excel {
  text-align: center;
}

.template_excel a {
  width: auto !important;
  color: #0e4d92 !important;
  padding: 10px 10px !important;
}

.export-modal {
  width: 25% !important;
}

.export-modal .selectAll-box {
  margin-bottom: 5px !important;
  font-weight: 600 !important;
}

.ant-dropdown {
  z-index: 2 !important;
}

.table-footer {
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
  padding-bottom: 15px !important;
  background-color: #e3f0ff;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top: hidden;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  margin-bottom: 20px !important;
}

.table-footer .ant-pagination-item {
  border-radius: 5px !important;
}

.table-footer .ant-pagination-item-active {
  border-radius: 5px !important;
  color: white !important;
  background-color: #0e4d92 !important;
}

.table-footer .ant-pagination-options .ant-select-selector {
  border-radius: 5px !important;
  border: 1px solid #0e4d92 !important;
}

.table-footer .ant-select-arrow {
  color: #0e4d92 !important;
}

.table-footer
  .ant-pagination-options-quick-jumper
  .ant-pagination-options-quick-jumper
  input {
  border-radius: 5px !important;
}

.contact-no-data-found {
  background: #fff !important;
}

.label-scrool .ant-select-selection-overflow {
  max-height: 55px !important;
  overflow-y: scroll !important;
}

.preview-table .preview-header b {
  font-weight: 800 !important;
}

.export-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative !important;
  top: 100px;
}

.export-loader span {
  font-size: 50px !important;
}
