*{
    box-sizing: border-box;
}

.report-body {
    position: fixed;
    width: 90%;
    /* margin: 90px 50px 0px 50px; */
    margin-top: 100px;
    margin-left: 70px;
    /* background-color: #f4f4f4; */
    background-color: rgb(242,242,242);
    border-radius: 10px;
  }
  
  .rc-virtual-list-holder{
    z-index: 10000 !important;
  }

  .report-body .report-dashboard {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    height: 84vh;
    padding: 20px 30px;
    overflow: scroll;
  }

.report-dashboard::-webkit-scrollbar {
    display: none;
}

.table-of-userwise::-webkit-scrollbar:vertical{
  display: none;
}

  .second-row{
    /* background-color: aqua; */
    box-shadow: 0px 3px 6px #00000029;
    margin-top: 20px;
    border-radius: 10px;
  }

  .first-row .col:nth-child(1){
    margin-right: 10px;
  }
  .first-row .col:nth-child(2){
    margin-left: 10px;
  }

  .first-row{
    margin-bottom: 20px;
  }

  .first-row .col{
    /* background-color: aqua; */
    box-shadow: 0px 3px 6px #00000029;
    height: 380px;
    max-width: 605px !important;
    border-radius: 10px;
  }

  .second-row .col{
    height: 380px;
  }


  .second-row .col-12{
    height: 380px;
  }

  .first-row .col .title, .second-row .col .title{
    font-family: "Mulish" !important;
    font-size: 16px;
    font-weight: bold;
    color: #0e4d92;
    margin-bottom: 10px !important;
    margin-top: 10px;
    text-align: center;
  }


  .table-of-userwise table{
    /* border-radius: 10px; */
    width: 100%;
    height: 90%;
    background-color: #ffff;
    /* margin: 0 auto; */
  }

  .table-of-userwise table th{
    color: #0e4d92;
    font-size: 13px;
    font-family: "Mulish" !important;
    font-weight: 700;
    padding: 6px 20px;
    text-align: left;
  }

  .table-of-userwise table thead{
    background-color: #f2f2f2;
    position: sticky;
    top: 0;
  }

  .table-of-userwise table tr{
    border-bottom: 1px solid #cfcfcf;
  }

  .table-of-userwise table tr td{
    padding: 5px 20px;
    color: #0e4d92;
    white-space: nowrap;
    font-size: 13px;
    font-family: "Mulish", sans-serif !important;
    font-weight: 500;
    text-align: left;
  }

  .table-of-userwise{
    box-shadow: rgb(0 0 0 / 45%) 0px 1px 5px -1px;
    overflow: scroll;
    max-height: 232px;
    min-height: 232px;
    /* padding: 10px; */
    background-color: #fff;
    width: 95%;
    /* border-radius: 10px; */
    
    margin: 0 auto;
  }

  .top-bar-userwise{
    box-shadow: rgb(0 0 0 / 45%) 0px 1px 4px 0px !important;
    margin: 0 auto;
    background-color: #E3F0FF;
    height: 40px;
    width: 95%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
  } 
  .footer-bar-userwise{
    box-shadow: rgb(0 0 0 / 45%) 0px 1px 5px -1px;
    margin: 0 auto;
    background-color: #E3F0FF;
    height: 40px;
    width: 95%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .footer-bar-userwise .footer-p{
    color: #0e4d92;
    font-family: "Mulish" !important;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px !important;
  }

  .pagi-user-wise{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 100% !important;
  }

  .pagi-user-wise .ant-pagination-item-active{
    background-color: #0e4d92 !important;
    color: white !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: "Mulish" !important;
  }

  .pagi-user-wise .ant-pagination-item, .pagi-user-wise .ant-pagination-next, .pagi-user-wise .ant-pagination-prev {
    border-radius: 5px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: "Mulish" !important;
    min-width: 27px !important;
    height: 27px !important;
    line-height: 27px !important;
  }

  .pagi-user-wise .ant-pagination-item a{
    padding: 0px !important;
  }

  .search-bar-user-wise{
    background-color: #F0F0F0;
    box-shadow: 0.5px 0.5px 1px 1px rgb(186 186 186);
    height: 30px;
    width: 160px;
    border-radius: 14px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
  }
  .pagi-datepicker {
    padding: 5px 15px 5px 8px !important;
    border-radius: 15px !important; 
    border: none !important;
    box-shadow: 0.5px 0.5px 1px 1px rgb(186 186 186) !important;
  }

  .pagi-datepicker .ant-picker-suffix {
    display:  none !important;
  }

  .pagi-datepicker .ant-picker-range-separator{
    padding: 0 !important;
    line-height: 0 !important;
  }

  .pagi-datepicker  .ant-picker-input > input{
    font-size: 13px !important;
    font-family: "Mulish" !important;
    font-weight: 500 !important;
    color: #0e4d92 !important;
  }

  .pagi-datepicker .ant-picker-active-bar{
    display: none !important;
  }

  .search-bar-user-wise  .search-wise-user{
    width: 110px;
    background-color: #F0F0F0;
    border: none;
    height: 100%;
    text-align: start;
    padding: 0px 5px;
  }

  .search-bar-user-wise .submit-btn{
    /* background-color: #0e4d92; */
    color: #0e4d92;
    width: 60px;
    height: 100%;
    border-top-right-radius: 14px;
    border-bottom-right-radius: 14px;
    border-left: 1px solid #0e4d92;
  }

  .search-bar-user-wise .search-icon-user-wise{
    border-top-left-radius: 14px !important;
    border-bottom-left-radius: 14px !important;
  }

  .upper-bar {
    display: flex;
    align-items: center;
    padding: 0 20px;
    justify-content: space-between;
  }

  .grid-icon-user{
    font-size: 20px;
    cursor: pointer;
    color: #0e4d92;
  }


  