.icon-for-totalNextFollow{
    opacity: 0;
}

.tr-for-totalNextFollow:hover .icon-for-totalNextFollow{
    opacity: 1;
}


.tr-for-attempNotes td:not(.att-lebels){
    max-width: 200px !important;
    white-space: pre-line;
}

