.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* By AHS */
/* ===== Scrollbar CSS ===== */
/* Firefox */
*:not(.table-responsive) {
  scrollbar-width: auto !important;
  scrollbar-color: #edeaea #ffffff !important;
}

/* Chrome, Edge, and Safari */
*:not(.table-responsive)::-webkit-scrollbar {
  width: 16px;
}

*:not(.table-responsive)::-webkit-scrollbar-track {
  background: #ffffff;
}

*:not(.table-responsive)::-webkit-scrollbar-thumb {
  background-color: #edeaea;
  border-radius: 10px;
  border: 3px solid #ffffff;
}

.table.table-striped-columns > tbody tr td {
  font-weight: 600 !important;
}

/* Date Picker */

span.anticon.anticon-close-circle {
  /* display: none; */
  /* opacity: 1; */
  visibility: hidden !important;
}

.table-responsive table {
  margin-bottom: 0px !important;
}

/* Auto Complete */
.ant-select-selection-search-input {
  text-align: left;
  /* caret-color: transparent;     */
}

/* *:focus {
  outline: 4px dashed darkorange !important;
} */

.addNotesTeemps .ant-modal-body{
  height: 480px !important;
  width:551px !important;
}

.addNotesTeemps{
  width: 551px !important;
}
