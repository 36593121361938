/* @import url("../cssStyle/loginPage.css"); */

.cdm-body {
  position: fixed;
  width: 90%;
  /* margin: 90px 50px 0px 50px; */
  margin-top: 100px;
  margin-left: 70px;
  background-color: #f2f2f2;
  border-radius: 10px;
}

.cdm-body .cdm-dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.cdm-body .dash-header {
  display: flex;
  justify-content: center;
  height: 50px;
  background-color: #e3f0ff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 100%;
}

.cdm-body .dash-header-title {
  height: 40px;
  background: var(--liner-blue-0);
  text-shadow: 0px 2px 2px #00000029 !important;
  color: white;
  font-size: 20px;
  text-align: center;
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  width: 400px;
  border-bottom-left-radius: 47px;
  border-bottom-right-radius: 47px;
}

.cdm-body .dash-body {
  width: 97%;
  border-radius: 10px;
  margin-top: 20px;
  display: flex;
  margin-bottom: 20px;
}

.dash-body .overview-bar {
  flex-basis: 70%;
  border-radius: 10px;
  /* background-color: white; */
  margin-right: 10px;
  display: flex;
  /* flex-direction: column; */
  padding: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
}

.overview-bar .over-top {
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
}

.overview-bar .overview {
  flex-basis: 50%;
}

.overview-bar .total-sub {
  flex-basis: 50%;
}

.dash-body .cdm-card-box {
  position: relative;
  width: 100%;
  /* height: 200px; */
  border-radius: 10px;
  /* background-color: #e3f0ff; */
}

.dash-body .managment-bar {
  flex-basis: 50%;
  border-radius: 10px;
  background-color: white;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
}

.dash-body .managment-bar-2 {
  flex-basis: 50%;
  border-radius: 10px;
  background-color: white;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  min-height: 75vh;
  max-height: 85vh;
}

.dash-body p {
  font-size: 15px;
  color: #043c79;
  text-align: center;
  font-weight: 700;
  font: normal normal bold 15px/19px Mulish;
  flex-basis: none;
}

.dash-body .cdm-card {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 10px;
  flex-basis: 50%;
}

.cdm-card-box .team_img_name {
  /* height: 60px; */
  border-radius: 50%;
  text-align: center;
}

.cdm-card-box .team_img_name p {
  font: normal normal medium 15px/19px Mulish !important;
  font-size: 15px;
  font-weight: 500;
  font-family: Mulish;
  letter-spacing: 0px;
  color: #0e4d92;
}

.cdm-card-box .team_img_name img {
  /* width: 100%; */
  clip-path: circle(30.3% at 53% 30%);
  width: 54px;
}

.for_team_img {
  height: 54px;
}

.cdm-card-box .row {
  /* padding: 10px 0px; */
}

.header_total_sub {
  display: flex;
  justify-content: center;
  align-items: center;
}

.header_total_sub ul {
  display: flex;
}

.header_total_sub ul li {
  height: 21px;
  color: white;
  background: #1b90ff 0% 0% no-repeat padding-box;
  border-radius: 3px;
  margin-right: 10px;
  padding: 0px 6px;
  cursor: pointer;
}

.header_total_sub p {
  color: #0e4d92;
  font-size: 15px;
  font-weight: bold;
  font-family: Mulish;
}

.body_total_sub {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.body_total_sub h1 {
  color: #0e4d92;
  font-size: 50px;
  font-weight: bold;
  font-family: Mulish;
  margin-bottom: 0;
}

.body_total_sub h3 {
  color: #0e4d92;
  font-size: 20px;
  font-weight: bold;
  font-family: Mulish;
  margin-bottom: 0;
}

.top_five_header {
  background: #1b90ff 0% 0% no-repeat padding-box;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.top_five_header p {
  color: white;
  margin-bottom: 0px;
}

.footer_total_sub {
  height: 200px;
  overflow-y: scroll;
  margin-top: 40px;
  padding: 0px 50px;
}

.footer_total_sub p {
  text-align: start;
}

.footer_total_sub li span {
  /* margin-left: 50px; */
  font-weight: 800;
}

.row>* {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.cdm-card-box .userImg {
  width: 60px;
  border-radius: 50%;
  clip-path: circle(30.3% at 53% 30%);
}

.cdm-card-box .userImg:nth-child(2) {
  position: absolute;
  top: 0px;
  left: 20px;
  z-index: 1;
}

.cdm-card-box .userImg:nth-child(3) {
  position: absolute;
  top: 0px;
  left: 40px;
  z-index: 2;
}

.cdm-card-box .userImg:nth-child(4) {
  position: absolute;
  top: 0px;
  left: 60px;
  z-index: 3;
}

.cdm-card-box .userImg:nth-child(5) {
  position: absolute;
  top: 0px;
  left: 80px;
  z-index: 4;
}

.cdm-card-box .userImg:nth-child(6) {
  position: absolute;
  top: 0px;
  left: 100px;
  z-index: 5;
}

.cdm-card-box .userImg:nth-child(7) {
  position: absolute;
  top: 0px;
  left: 120px;
  z-index: 6;
}

.cdm-card-box .userImg:nth-child(8) {
  position: absolute;
  top: 0px;
  left: 140px;
  z-index: 7;
}

.cdm-card-box .userImg:nth-child(9) {
  position: absolute;
  top: 0px;
  left: 160px;
  z-index: 8;
}

/* AHS */

.single-note {
  display: flex;
  flex-direction: column;
}

.cdm-card-box {
  height: 70%;
}

.card-block ul li h6 {
  border: 1px solid;
  margin-bottom: 1rem;
  padding: 0.5rem;
}

.managment-bar-2>.card-header {
  display: flex;
  justify-content: end;
}

.card-block ul .notes-view {
  display: flex;
  width: 650px;
  overflow-x: hidden;
  color: grey;
}

.card-block ul {
  margin-top: 1rem;
  height: 370px;
  overflow-y: scroll;
}

.card-block ul li {
  margin: 10px 5px 15px 5px;
  padding: 10px;
  background-color: #f2f2f2;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
    rgb(209, 213, 219) 0px 0px 0px 1px inset;
}

span.highlighted-userName {
  /* justify-content: start; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  font-size: 15px;
  color: black;
  width: 100%;
}

.card-header {
  display: flex;
  justify-content: space-around;
  background-color: #E3F0FF !important;
}

.next-follow-up {
  display: flex;
  flex-direction: column;

  padding: 0.4rem;
}

.ant-popover-arrow-content::before {
  background: aliceblue !important;
}

.popover-content .title {
  /* border-bottom: 1px solid; */
  font-size: 15px;
  font-weight: 700;
}

.popover-content ul li {
  margin: 0.5rem 0;
  padding: 3px 8px 3px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset;
}

.cdm-card-header {
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid;
  padding-bottom: 7px;
}

.cdm-card-header>div {
  flex-basis: 33.33%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.searchFields {
  display: flex;
  justify-content: space-between;
  background: #E3F0FF;
  padding: 1rem;
}

.searchFields .searchByUserName {
  border: 1px solid transparent;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 1px #00000029;
  margin-right: 1rem;
  padding: 3px;
  /* background-color: #F2F2F2 !important; */
  border-radius: 8px !important;
}

.searchFields .searchByUserName::placeholder {
  color: #bebebe;
}

.searchFields span.anticon.anticon-close-circle {
  display: block;
}

.searchFields .ant-select-selection-overflow {
  max-height: 55px;
  overflow-y: scroll;
}

/* End */