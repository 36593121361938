
.table-boxs table thead th {
    background-color: #edeaea !important;
    /* text-align: center; */
}

.table-responsive{
    padding-right: -10px !important;
}

.contact-table-md tbody tr {
    font-weight: 700 !important;
}

.contact-table-md tbody tr td {
    font-weight: 600;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    /* background-color: white !important; */
}

.contact-table-md thead th:last-child {background-color: #edeaea !important;}


.contact-table-md tbody tr:nth-child(even) td:last-child {
    background-color: #edeaea!important;
}

.contact-table-md thead th{
    white-space: nowrap;
}

.contact-table-md thead{
    position: sticky;
    top: 0;
}

.contact-table-md tbody  td{
    white-space: nowrap;
}


.contact-table-md thead th:last-child{
    position: sticky !important;
    right: 0px !important;
}

.contact-table-md tbody tr td:last-child{
    position: sticky !important;
    right: 0px !important;
}

.contact-table-md tbody tr:nth-child(odd) td:last-child{
    background-color: #edeaea !important;
}



.contact-table-md tbody tr{
    background-color: white !important;
    color: rgb(14 77 146) !important;
}
.client-body-tr:nth-child(odd):hover
.table-hover tbody tr:hover{
    background-color: #edeaea !important;
    color: rgb(14 77 146) !important;
}


.contact-table-md tbody tr:hover{
    background-color: #E6EDF6  !important;
}



/* By Akram Shekh */

.cContactForm span.ant-select-arrow.ant-select-arrow-loading {
    color: red;
    font-size: 18px;
}