* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* font-family: sans-serif; */
  text-decoration: none;
  list-style: none;
  outline: none !important;
  font-weight: 600;
}

.client-table {
  margin-left: 150px;
  margin-top: 20px;
  width: 1200px;
  overflow-x: scroll;
  overflow-y: scroll;
}

.ant-pagination{
  font-weight: 600;
}

table.table thead th {
  color: #0e4d92;
  font-weight: 700;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
  position: relative;
  top: 10px;
  margin-bottom: 60px;
}

.logo {
  height: 90px;
  margin-left: 2%;
}

.logo .logo-img {
  height: 90px;
}

.lg-btn {
  font-size: 16px;
  background-color: #2a91b1;
  line-height: 1.5;
  color: white;
  cursor: pointer;
  width: 60px;
  height: 30px;
  border: none;
  margin-right: 1%;
}

.table-data {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  position: relative;
  top: 75px;
}

.table-data td,
.table-data th {
  border: 1px solid #ddd;
  padding: 10px;
  border: none;
  font-size: 17px;
  font-weight: 700;
}

/* .table-data tr:nth-child(even){
    
} */
/* .table-data tr:hover{
    background-color: #ddd;
} */

.table-data th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #2a91b1;
  color: white;
}

.form-name {
  width: 30%;
  border: 1px solid black;
  background-color: #2a91b1;
}

.form-name .row {
  display: flex;
  position: relative;
  margin: 10px 0;
}

.form-name .col-25 {
  width: 80px;
}

.form-name input[type="text"] {
  width: 200px;
  height: 25px;
  padding: 5px;
  font-family: sans-serif;
  font-size: 15px;
}

.table-1 .ant-table {
  background-color: rgb(116, 131, 85);
  text-align: center;
}

/* .table-1 .ant-spin-container{
    background-color: blue;
} */
/* .table-1 .ant-spin-nested-loading{
    background-color: blueviolet;
} */
/* .table-1 .ant-table-wrapper{
    background-color: cadetblue;
} */
.table-1 .ant-table table {
  text-align: center;
}

.table-1 .ant-pagination {
  /* background-color: wheat; */
  /* justify-content: center; */
}

.table-1 .ant-table-container {
  background-color: #f2f2f2;
  position: relative;
  left: 150px;
  width: calc(100% - 150px);
}

.table-1 .ant-table-thead>tr>th {
  font-weight: bold;
}

.title-1 h1 {
  background-color: aqua;
  left: 150px;
  position: relative;
}

.title-1 .ant-input-group .ant-input {
  width: 20%;
  float: right;
}

.title-1 .ant-typography {
  left: 150px;
  position: relative;
  width: 100%;
}

.title-1 {
  display: flex;
}

.wrapper-table {
  margin-left: 0px;
  width: 100%;
  position: relative;
  overflow: hidden;
  top: 75px;
  height: calc(100vh - 80px);
}

.table-box {
  position: relative;
  /* left: 200px; */
  width: 100%;
  max-height: 500px;
  overflow-x: scroll;
  overflow-y: scroll;
  padding: 0px;
  border: 1px solid rgb(172, 172, 172);
  border-radius: 3px;
}

.table-box table {
  width: 100%;
  text-align: left;
  font-size: 16px;
  font-family: sans-serif;
  border-collapse: collapse;
}

.table-box table tr:nth-child(even) {
  background-color: #f3f3f3;
}

.table-box table tr:nth-child(odd) {
  background-color: white;
}

.table-box table tr:hover {
  background-color: #e0dbdb;
}

.table-box table th {
  position: sticky;
  top: 0;
  padding: 6px 15px;
  text-align: left;
  background-color: #e7f1f5;
  color: black;
  font-size: 15px;
  font-weight: 500;
}

.table-box table td {
  padding: 12px 15px;
  text-align: left;
  font-size: 15px;
}

.search-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 20px !important;
  padding-bottom: 10px !important;
  background-color: #E3F0FF !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom: none !important;
  box-shadow: rgba(0, 0, 0, 0.45) 0px -20px 20px -20px !important;
  margin-top: 20px !important;
  /* box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px; */
  /* align-items: center; */
  /* padding-left: 8%; */
  /* padding-right: 4%; */
}

.search-any-btn {
  /* display: flex;
  position: relative;
  margin-left: -126px; */
}

.search-bar .button-right {
  /* display: flex; */
  /* align-items: center; */
  margin-top: 18px;
}

.button-right a,
.button-right a:hover {
  color: #4285f4;
  font-size: 16px;
  margin-right: 15px;
}

.btn-for-right button {
  cursor: pointer;
}

.top-three-btn button span {
  /* width: 30px;
  height: 30px;
  background-color: white;
  border: 1px solid #0e4d92;
  border-radius: 0.2rem;
  margin-right: 5px;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  color: #0e4d92;
  font-size: 14px; */
  color: #0e4d92;
  font-size: 18px;
  border: none !important;
  margin-top: -5px !important;
  line-height: 18px !important;
}

.top-three-btn {
  margin-top: -15px !important;
}

.top-three-btn button span:nth-child(1) {
  padding: 0px !important;
}

.top-three-btn button span:nth-child(2) {
  margin-left: 4px !important;
  font-size: 13px !important;
  font-weight: 500;
}

.top-three-btn button span:nth-child(3) {
  /* margin-left: 4px !important;
  font-size: 12px !important;
  font-weight: 500;
  color: white !important;
  background-color: #043c79 !important; */
  font-size: 15px !important;
}

.top-three-btn button,
.top-three-btn button:hover {
  border: 2px solid #d5e9ff !important;
  border-radius: 7px !important;
  /* box-shadow: 1px 1px 1px 2px #0e4d92; */
  height: 33px !important;
  /* width: 50px !important; */
}

.top-three-btn button:nth-child(2) {
  margin-left: 5px !important;
  width: 72px !important;
}

.top-three-btn:nth-child(3) a {
  color: white !important;
}

.top-three-btn button:nth-child(3) {
  margin-left: 5px !important;
  font-size: 12px;
  background-color: #043c79;
  margin-top: -5px !important;
  border: 0px !important;
  padding: 0px 15px 0px 15px !important;
  height: 30px !important;
  position: relative;
  top: 0px;
  border-radius: 5px !important;
}

.top-three-btn button:nth-child(3) a {
  color: white !important;
  font-size: 13px !important;
  margin-right: 0px m !important;
}

.top-three-btn button:nth-child(3) span {
  color: white !important;
  background-color: #043c79 !important;
  font-size: 14px !important;
  line-height: 15px;
  padding-top: 8px;
  margin-top: 5px;
}

.top-three-btn button:nth-child(1) {
  width: 50px !important;
}

.top-three-btn button:nth-child(1) svg {
  height: 20px !important;
}

.bottom-three-btn {
  float: right;
  margin-top: 7px !important;
}

.bottom-three-btn button,
.bottom-three-btn button:hover {
  border: 1px solid #043c79 !important;
  border-radius: 7px !important;
  height: 27px !important;
  width: 35px;
  margin-left: 7px !important;
}

.bottom-three-btn button span:nth-child(1) {
  color: #043c79 !important;
  font-size: 18px !important;
}

.search-any-btn button {
  cursor: pointer;
  font-size: 15px;
  padding: 2px 12px;
  font-size: 14px;
  color: #666;
  background-color: white !important;
  color: #0e4d92 !important;
  border: none;
  width: 454px;
}

.search-any-btn button:hover {
  background-color: white !important;
  color: #0e4d92 !important;
  border: none !important;
}

.search-bar-left input[type="text"] {
  border: none;
  font-size: 15px;
  padding-left: 30px;
  height: 36px;
  border-right: 1px solid #0e4d92 !important;
  color: #0e4d92;
  text-align: left;
}

.search-bar-left input::placeholder {
  color: #0e4d92;
}

.client-title .side-to-side {
  display: flex;
  align-items: center;
}

.client-title .buttons {
  display: flex;
  justify-content: space-between;
}

.table-box table .filter-btn {
  cursor: pointer;
  font-size: 14px;
  margin-left: 5px;
  display: flex;
  align-items: center;
  color: #0e4d92;
  font-size: 12px;
}

.table-box table thead .th-title {
  display: flex;
  align-items: center;
  text-align: center;
}

.th-title-btn {
  color: white;
  /* display: flex; */
  align-items: center;
  margin: 0 0.5rem;
  cursor: pointer;
  border: none;
  background-color: #0a9100;
  padding: 3px 12px;
  border-radius: 18px;
  position: absolute;
  left: 35px;
  top: 12px;
  z-index: 10;
  opacity: 1;
}

.th-title-btn svg {
  margin-left: 5px;
}

.dropdown-content {
  display: flex;
  align-items: center;
  border: 1px solid rgb(155, 155, 155);
  position: absolute;
  top: 0px;
  padding: 10px;
  background-color: white;
  border-radius: 12px;
}

.main-menu-div .dropdown-content {
  display: flex;
  align-items: center;
  border: 1px solid #d5e9ff;
  box-shadow: 0.5px 0.5px 1px 1px rgb(186, 186, 186);
  position: absolute;
  top: 4px;
  right: -40px;
  padding: 10px;
  background-color: white;
  border-radius: 12px;
}

.dropdown-content .dropdown-content-list:nth-child(1) {
  border-right: 1px solid #0e4d92;
  padding-left: 10px;
}

.main-menu-div .dropdown-content .dropdown-content-list:nth-child(1) {
  border-right: 0px solid #0e4d92 !important;
}

.dropdown-content .dropdown-content-list:nth-child(2) {
  padding-left: 10px;
}

.dropdown-content-list ul {
  margin-bottom: 0px !important;
}

.dropdown-content-list {
  /* margin: 10px 0px 5px 10px; */
}

.dropdown-content-list li {
  display: block;
  width: 165px;
  text-align: start;
  margin: 3px;
}

.dropdown-content-list li a {
  color: #0e4d92;
}

.dropdown-content-list li a:hover {
  color: #0e4d92;
}

.search-any-btn .input-box {
  border: 1px solid white !important;
  background-color: white !important;
  box-shadow: 0.5px 0.5px 1px 1px rgb(186, 186, 186);
  border-radius: 22px;
  padding: 0px 10px;
  display: flex;
  align-items: center;
  width: 472px;
}

.search-any-btn .input-box .filter-btn {
  color: #0e4d92;
  cursor: pointer;
  margin-right: 10px;
  font-size: 15px;
  position: relative;
  right: 20px;
}

.dropdown-content-any-btn {
  display: flex;
  align-items: center;
  /* border: 1px solid rgb(136, 136, 136); */
  box-shadow: 0.5px 0.5px 1px 1px gray;
  position: absolute;
  top: 6px;
  right: -11px;
  z-index: 1000;
  background-color: white;
  padding: 12px;
  width: 300px;
  border-radius: 12px;
}

.dropdown-content-any-btn li {
  margin: 5px;
}

.dropdown-content-any-btn ul {
  margin-bottom: 0;
}

.dropdown-content-any-btn li a {
  color: #0e4d92;
}

.dropdown-content-any-btn li a:hover {
  color: #0e4d92;
}

.dropdown-content-list-bd-lf {
  margin-top: -25px;
  padding-left: 0px;
}

.dropdown-content-list-bd-lf li {
  margin-left: 10px;
}

.dropdown-content-list-bd-lf li a {
  color: #0e4d92;
}

.dropdown-content-list-1 {
  border-right: 1px solid #0e4d92;
  padding-right: 0px;
  width: 125px !important;
}

.filter-sort-lis .filter-sort-atoz {
  display: flex;
  align-items: center;
  border: 1px solid #666;
  border-radius: 3px;
}

.filter-sort-lis .filter-btn {
  display: flex;
  color: black !important;
}

.filter-container {
  position: absolute;
  top: 35px;
  z-index: 1000000;
  background-color: #e7f1f5;
  width: 350px;
  border: 1px solid rgb(155, 155, 155);
  border-radius: 3px;
  /* display: none; */
}

.filter-container .filter-null {
  display: flex;
}

.filter-container .filter-sort-lis {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 10px;
}

.filter-sort-atoz .filter-btn {
  /* border: 1px solid #666; */
  display: flex;
  align-items: center;
  padding: 6px 8px;
  font-size: 12px;
  border-radius: 3px;
}

.filter-null .null-btn {
  border: 1px solid #666;
  font-size: 12px;
  padding: 6px 8px;
  float: left;
  border-radius: 3px;
  align-items: center;
  display: flex;
  cursor: pointer;
  margin-right: 10px;
}

.filter-null .null-btn input[type="checkbox"] {
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.filter-sort-atoz .filter-btn input[type="checkbox"] {
  margin-right: 5px;
}

.filter-sort-atoz .fa-sort-icon {
  margin-left: 8px;
}

.clients-title {
  display: flex;
  align-items: center;
  margin-top: 20px;
  text-align: center;
  justify-content: center;
  position: relative;
  box-shadow: 0 10px 15px rgb(25 25 25 / 10%);
  background: #ffffff;
  padding-bottom: 20px;
  width: 100% !important;
  /* padding-left: 50px; */
  padding-right: 50px;
}

.clients-btn {
  /* display: flex; */
  /* margin-right: auto; */
}

.clients-btn button {
  margin-right: 15px;
  font-size: 14px;
  padding: 6px 12px;
  cursor: pointer;
  position: relative;
  /* border: 1px solid #0e4d92; */
  box-shadow: 0.5px 0.5px 1px 1px rgb(186, 186, 186);
  color: white;
  background-color: #0e4d92;
  border-radius: 20px;
}

.clients-btn button:last-child {
  margin-right: 20px;
  font-size: 15px;
  left: 0px !important;
  top: 0px !important;
  color: #0e4d92;
  background-color: white;
  width: 75px;
  height: 32px;
  border: 1px solid #d5e9ff;
  box-shadow: 0.5px 0.5px 1px 1px rgb(186, 186, 186);
  padding: 0px !important;
}

.clients-btn button .filter-btn {
  margin-right: 5px;
  font-size: 15px;
}

.new-client-btn {
  background-color: #0e4d92;
  color: white;
  /* border: 1px solid #666; */
  padding: 8px 8px;
  border-radius: 2px;
}

.clients-text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.clients-title .clients-text h4 {
  color: #0e4d92;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 0.8rem;
  margin-left: 15px;
}

.export-links {
  position: absolute;
  top: 29px;
  right: 0;
  background-color: white;
  border: 1px solid #666;
  width: 100px;
  padding: 10px 10px;
  float: right;
  border-radius: 3px;
  z-index: 10;
  display: none;
}

.export-links ul {
  margin-bottom: 0;
}

.export-links ul li {
  padding: 6px 0px;
  width: 100%;
}

.export-links ul li a {
  color: #666;
}

.export-links ul li:hover a {
  color: #0e4d92;
}

/* Pagination start  */

.pagination-box {
  text-align: center;
}

.pagination {
  display: flex;
  justify-content: end;
  margin-top: 15px;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border: 1px solid #ddd;
}

.pagination a.active {
  background-color: #0e4d92;
  color: white;
  border: 1px solid #0e4d92;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
}

.pagination a:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.pagination a:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.search-bar-left {
  margin-left: 2rem !important;
  /* float: right;
  /* margin-right: auto; */
}

.top-search-group .first-of-top-three{
  background-color:  white !important;
}
/* Pagination end */

.table-boxs {
  padding: 2px;
  position: relative;
  padding-left: 4%;
  padding-right: 4%;
}

.table-boxs table {}

.table-boxs table thead {
  background-color: #d5e9ff;
  /* color: white; */
  /* position: sticky;
  top: 0;
  z-index: 2; */
}

.table-boxs tbody {
  overflow-y: scroll;
  max-height: 100px !important;
}

.table-boxs table td {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.table-boxs table th {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 15px;
  font-weight: 400;
}

/* .table-boxs .pagination li:hover{
  background-color: #0e4d92;
  color: black;
} */

/* .table-boxs .pagination a.page-link{
  background-color: #0e4d92 !important;
} */

.pagination .page-item.active .page-link {
  background-color: #0e4d92 !important;
}

.ant-table-thead tr th {
  background-color: #d5e9ff !important;
  color: black !important;
}

.ant-table-container table>tbody>tr:nth-child(even) {
  background-color: #e6edf6;
}

.ant-table-container table>tbody>tr:nth-child(odd) {
  background-color: white;
}

/* .ant-table-container table > tbody > tr:nth-child(even):hover {
  background-color: white !important;
}

.ant-table-container table > tbody > tr:nth-child(odd):hover {
  background-color: #e6edf6 !important;
} */

.ant-table-tbody>tr.ant-table-row:nth-child(even)>td {
  /* background-color: #d5e9ff !important; */
}

.ant-table-tbody>tr.ant-table-row:nth-child(odd)>td {
  /* background-color: white !important; */
}

.ant-table-container table>thead>tr:first-child th:first-child,
.ant-table-container table>tbody>tr td {
  padding-right: 0px;
  color: #0e4d92;
  padding: 10px 10px !important;
}

.ant-table-content table {
  /* border: 1px solid #0e4d92; */
  /* margin-top: 10px; */
  border-radius: 1px;
  white-space: nowrap;
  /* height: 100% !important; */
  /* min-height: 100% !important; */
}

.search-any-btn button .anticon-down {
  display: flex;
  align-items: center;
}

.new-form-data {
  border: 1px solid black;
  position: absolute;
  top: 10px;
  padding: 10px;
  z-index: 11;
  background-color: #2a91b1;
}

.ul-of-newform {
  position: relative;
  z-index: 1;
  background-color: white;
  z-index: 12;
}

.new-form-data input[type="text"],
.new-form-data input[type="email"] {
  padding: 7px 10px;
  border-radius: 7px;
  border: 2px solid #b3bac5;
  height: 40px;
  margin-bottom: 10px;
  margin-left: 10px;
}

.form-group {
  display: flex;
}

.add-client-input-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .clients-text span svg {
  font-size: 10px;
} */

.header-searchbar .search-input input[type="text"],
.header-searchbar .search-input input::placeholder {
  color: #0e4d92;
}

.search-input {
  border: 1px solid #d5e9ff;
  box-shadow: 0.5px 1.5px 1px 1px rgb(186 186 186);
  padding: 0px 15px;
  border-radius: 25px;
  width: 374px;
  display: flex;
  align-items: center;
}

.search-input button {
  display: flex;
  align-items: center;
  height: 39px;
  margin-left: 40px;
  color: #0e4d92;
  background-color: white;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.search-input input {
  margin-left: 10px;
  font-size: 15px;
  /* border-right: 1px solid #0e4d92 !important; */
  height: 40px;
  width: 200px;
}

.search-input input,
button {
  border: none;
}

.header-searchbar {
  position: absolute;
  right: 4%;
}

.button-right button span svg {
  margin-top: -5px;
}

.table-footer {
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  float: left;
}

.pagination-page-number {
  display: flex;
  align-items: center;
  border-radius: 20px;
  border: 1px solid #0e4d92;
  color: #0e4d92;
}

.pagination-page-number>p {
  margin: 0px !important;
  border-right: 1px solid #0e4d92;
  padding: 5px 10px;
}

.pagination-page-number input {
  width: 50px;
}

.pagination-page-number input[type="text"] {
  border: none;
  border-radius: 10px;
  padding: 0px 5px;
}

.ant-checkbox-inner {
  border-radius: 50% !important;
}

.ant-table-content {
  overflow: scroll;
  height: 65vh !important;
  width: 86vw !important;
  /* padding-left: 8%; */
}

.ant-pagination-options .ant-select-selector {
  border-radius: 15px !important;
}

.ant-pagination-options .ant-select-selector span {
  color: #0e4d92 !important;
}

.ant-pagination-options-quick-jumper input {
  border-radius: 5px !important;
  outline: none !important;
  color: #0e4d92 !important;
}

.ant-pagination-options-quick-jumper {
  color: #0e4d92 !important;
}

.ant-pagination-options-quick-jumper input:hover {
  border-color: #0e4d92 !important;
  border-right-width: 0px !important;
}

.dw-for-all-views {
  padding: 0px !important;
  border-radius: 18px !important;
  right: 0px;
  top: 5px;
}

.dw--list-for-all-views {
  margin: 0px !important;
}

.dw--list-for-all-views:nth-child(2) {
  padding-left: 0px !important;
}

.dw--list-for-all-views ul {
  margin-bottom: 0px !important;
  padding: 2px 30px !important;
}

.dw--list-for-all-views ul li {
  margin: 0px !important;
  text-align: center !important;
}

.dw--list-for-all-views ul button {
  background-color: white !important;
  color: #0e4d92 !important;
  border: none;
}

.dw--list-for-all-views ul .ant-btn {
  padding: 0px !important;
}

.ant-modal-title {
  color: #043c79 !important;
  font-weight: 600 !important;
  font-size: 20px !important;
}

.ant-modal-close-x {
  width: 44px !important;
  height: 30px !important;
  font-size: 16px;
  font-style: normal;
  line-height: 42px !important;
  color: #0e4d92 !important;
}

.ant-modal-close-x span {
  font-size: 20px;
}

.ant-modal-footer {
  display: none !important;
  flex-direction: row-reverse !important;
  text-align: start !important;
  width: 210px !important;
  border-top: 0px solid #f0f0f0 !important;
  /* position: absolute;
  bottom: 20px; */
}

.ant-modal-footer .ant-btn-primary {
  background-color: #0e4d92 !important;
  border: none !important;
  border-radius: 5px !important;
  padding: 0px 0px !important;
  margin-left: 4px;
  width: 65px;
}

.ant-btn-default {
  /* margin-left: 30px !important; */
  /* color: #a2a2a2 !important; */
  outline: none !important;
  padding: 0px 0px !important;
  border-radius: 5px !important;
  width: 65px;
}

.ant-btn-default:hover {
  border: 1px solid #d2d2d2 !important;
}

.add-view-group {
  display: flex;
}

.create-view-left {
  border-right: 1px solid #c7c7c7;
  padding-right: 5px !important;
}

.create-view-left .p-tag p {
  color: #0e4d92;
  font-size: 14px;
  font-weight: 500;
}

.create-view-left select {
  color: #0e4d92;
  border: none;
  background-color: #d5e9ff;
  padding: 3px 16px 3px 8px;
  margin: 0px 5px;
  font-size: 15px;
  border-radius: 3px;
  width: 138px;
  height: 24px;
}

.create-view-right {
  padding-left: 15px;
}

.create-view-right .ant-switch-checked {
  float: right !important;
  background-color: #043c79 !important;
}

.create-view-right .ant-switch {
  float: right !important;
  /* background-color: #043c79 !important; */
}

.create-view-right .search-in-create-view {
  display: flex;
  margin-top: 30px;
  position: relative;
}

.create-view-right .search-in-create-view input {
  border: none;
  background-color: #d5e9ff;
  color: #043c79;
  padding: 2px 25px 2px 8px;
  border-radius: 3px;
  margin: 8px 0px;
  width: 250px;
}

.create-view-right .search-in-create-view input::placeholder {
  color: #989898;
}

.search-in-create-view .anticon {
  height: 14px;
  position: absolute;
  right: 8px;
  top: 14px;
  cursor: pointer;
  color: #043c79;
}

.select-group-right select {
  color: #043c79;
  border: none;
  background-color: #d5e9ff;
  padding: 4px 70px 4px 4px;
  border-radius: 3px;
  margin: 8px 0px;
  width: 100%;
}

.create-view-right .p-tag p {
  color: #043c79;
  font-size: 14px;
  font-weight: 600;
  margin: 8px 0px;
}

.list-of-view ul li {
  color: #043c79;
  display: flex;
  align-items: center;
  background-color: #d5e9ff;
  padding: 1px 20px 1px 4px;
  font-size: 15px;
  border-radius: 3px;
  position: relative;
  margin: 6px 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.list-of-view ul li span.anticon {
  width: 18px;
  /* position: absolute; */
  /* right: 0px; */
  margin-left: -13px;
}

.list-of-view ul li span:nth-child(3) {
  position: absolute;
  right: 4px;
  cursor: pointer;
  font-size: 14px;
}

.select-group-left {
  display: flex;
}

.ant-modal-content {
  /* width: 610px !important;
  position: fixed !important; */
  scroll-behavior: smooth !important;
  /* height: 100%; */
}

.main-search-btn svg {
  padding-left: 20px !important;
}

.custom_pagination ul li {
  color: #043c79 !important;
  border-radius: 50% !important;
}

.ant-pagination-next:hover .ant-pagination-next button span svg,
.ant-pagination-item-active,
.ant-pagination-item:hover,
.ant-pagination-prev button:hover,
.ant-pagination-next button:hover,
.ant-pagination-prev button:hover span svg,
.ant-pagination-next button:hover span svg,
.ant-pagination-next button span svg,
.ant-pagination-prev button span svg {
  border-color: #043c79 !important;
  color: #043c79 !important;
}

.ant-pagination-prev,
.ant-pagination-next {
  position: relative;
}

.ant-pagination-prev button span,
.ant-pagination-next button span {
  position: absolute;
  top: 31%;
  left: 31%;
}

/* .ant-select:hover .ant-select-selector,
.ant-select .ant-select-selector {
  border: 1px solid #043c79 !important;
} */

.ant-pagination-options-quick-jumper input {
  border: 1px solid #043c79 !important;
}

.first-of-top-three {
  position: relative !important;
  border: 1px solid #d5e9ff;
  margin-right: 5px !important;
  box-shadow: 0.5px 0.5px 1px 1px rgb(186 186 186);
  border-radius: 18px !important;
  padding: 6px 15px 6px 15px;
}

.import-export-btn {
  background-color: white;
  /* position: absolute;
  top: 35px;
  left: -20px; */
  border-radius: 8px;
  border: 1px solid #d5e9ff;
  box-shadow: 0px 2px 3px 1px rgb(186, 186, 186);
  z-index: 2;
  position: relative;
  left: -10px;
}

.import-export-btn li {
  padding: 3px 5px 3px 5px;
  width: 88px;
  text-align: center;
}

.import-export-btn li a,
.import-export-btn li a:hover {
  color: #043c79;
  font-size: 14px;
  margin-right: 0px;
}

.ant-pagination-prev button,
.ant-pagination-next button {
  border-radius: 50% !important;
}

.ant-modal-content .form-lable-group .ant-btn-default {
  width: auto !important;
  margin: 0px !important;
  color: black !important;
  border: 1px solid rgba(0, 0, 0, 0.85) !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  height: 90% !important;
}

.cnt-div {
  width: 650px !important;
}

.top-search-group {
  display: flex;
  justify-content: end;
  align-items: center;
}

.two-states {
  display: flex;
  justify-content: space-between;
}

.change-status .ant-form-item-row {
  width: 200px !important;
}