.conact_hide_show_modal {
  width: 25% !important;
}

.contact_hide_show {
  margin-top: 20px !important;
}
.conact_hide_show_modal .select-all {
  margin-bottom: 15px !important;
  font-weight: 600 !important;
}

.add-client-modal {
  width: 70% !important;
}

.contact-modal-div {
  width: 73% !important;
}

.contact_thead {
  position: relative;
}

.contact_thead th::before {
  position: absolute;
  width: 1px;
  height: 1.6em;
  background-color: rgba(0, 0, 0, 0.06);
  content: "";
  top: 10px;
}

.contact_thead th:first-child::before {
  position: absolute;
  width: 0px !important;
  height: 1.6em;
  background-color: rgba(0, 0, 0, 0.06);
  content: "";
  top: 10px;
}

.contact_thead th:last-child::before {
  position: absolute;
  width: 0px !important;
  height: 1.6em;
  background-color: rgba(0, 0, 0, 0.06);
  content: "";
  top: 10px;
}

.anticon-sort-ascending {
  margin-right: 5px !important;
}

.ant-modal-wrap > .contact-modal-div {
  width: 90% !important;
}
.ant-modal-root:has(.contact-modal-div) {
  z-index: 900;
  position: absolute;
}
