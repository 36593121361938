*{
    margin: 0;
    padding: 0;
    font-family:sans-serif;
    outline: none;
    text-decoration: none;
}
body{
    /* background: linear-gradient(#e66465, #9198e5); */
    background-repeat: no-repeat;
    background-position: center;
    background-size:cover;
    height: 100vh;
}   
.hero-s{
    width: 90%;
    height: 90vh;
    margin: auto;
    margin-top: 40px;
    position: relative;
    
}

.form-group-s{
    width: 50%;
    text-align: center;
    background-color: white;
    position: absolute;
    left: 25%;
    top: 25%;
}

.form-group-s .logo-tp-s{
    background-color: #f3ec78;
    background-image: linear-gradient(45deg, #fa0000, #2f04f0);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
    margin-bottom: 10px;
    padding-top: 30px;
    margin-top: 20px;
    margin-bottom: 30px;

}

.form-tag-s input[type="password"]{
    border: none;
    border-bottom: 1px solid black;
    font-size: 20px;
    padding: 20px 0px;
    margin: 10px 0;
    width: 50%;
    
}
.form-tag-s .frm-btn-s{
    background-color: rgb(54, 192, 238);
    border: none;
    cursor: pointer;
    width: 40%;
    padding: 10px;
    font-size: 20px;
    color: white;
    margin-top: 40px;
    margin-bottom: 20px;
}
.form-tag-s .fgt-btn-s{
    color: black;
    font-size: 18px;    
}


@media screen and (max-height:550px) {
    
    .form-group-s .logo-tp-s{
        width: 50%;
        align-items: center;
        margin: 0 auto;
    }
    .form-group-s{
        width: 50%;
        top: 15%;
        margin: auto 0;
    
    }
    form{
        margin: 0 auto;
        height: auto;
    }
    
}