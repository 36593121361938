.sidebar-wrapper {
  background-color: rgb(255, 255, 255);
  min-height: 100vh;
  letter-spacing: 0px;
  word-break: break-word;
  z-index: 1000 !important;
}

.sidebar-options {
  position: fixed;
  top: 0;
  width: 100%;
  height: 70px;
  text-align: center;
  z-index: 99;
  transition: all 0.2s ease-in-out 0s;
  overflow-x: hidden;
  background: rgb(248, 251, 255);
  box-shadow: rgb(225, 231, 237) 0px 0px 30px;
  background-color: #0e4d92;
  display: flex;
  justify-content: space-between;
}

.sidebar-options .profile {
  display: inline-block;
  position: relative;
  object-fit: cover;
  cursor: pointer;
  border-radius: 50%;
  justify-content: center;
  margin-top: 20px;
}

.sidebar-options .profile img {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 1px;
  max-width: inherit;
}

.sidebar-options .profile .profile-content {
  width: 110px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}

.sidebar-options .profile-content h4 {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: white;
  margin-bottom: 4px;
  margin-top: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.sidebar-options .profile-content p {
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 140%;
  color: white;
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-options .main-menu {
  margin-bottom: 0px !important;
  display: flex;
  align-items: center;
  margin-right: 40px;
  font-weight: 500;
}

.sidebar-options .middle-menu {
  margin-bottom: 0px !important;
}

.middle-menu li:nth-child(2) a {
  padding-left: 18px !important;
}
.middle-menu li:nth-child(3) a {
  padding-left: 18px !important;
}

.sidebar-options ul li a.main-menu-profile {
  border: 1px solid rgb(72, 87, 104);
  border-radius: 12px;
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: flex-start;
  padding: 5px 11px;
  position: relative;
  padding-left: 13px;
}

.sidebar-options ul li a.main-menu-profile::after {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-right: 2px solid rgb(72, 87, 104);
  border-top: 2px solid rgb(72, 87, 104);
  transform: rotate(45deg) translateY(-47%);
  right: 15px;
  top: 47%;
  opacity: 0;
  transition: all 0.2s ease-in-out 0s;
}

.sidebar-options:hover a.main-menu-profile::after {
  opacity: 1;
}

.sidebar-options ul li a.main-menu-profile i {
  opacity: 1;
  transition: all 0.2s ease-in-out 0s;
}

.sidebar-options ul li a.main-menu-profile span {
  position: absolute;
  left: 40px;
  top: 50%;
  transform: translateY(-50%);
  font: 500 14px Arial;
  color: rgb(72, 87, 104);
}

.sidebar-options ul li a.active-menu {
  background-color: rgb(168, 115, 250);
  color: rgb(72, 87, 104);
}

.sidebar-options ul li a.active-menu {
  background-color: rgb(168, 115, 250);
  color: rgb(72, 87, 104);
}

.sidebar-options .logout {
  text-align: left;
  position: relative;
  white-space: nowrap;
  border: none;
  background: transparent;
  padding: 0 0 0 9px;
  margin-left: 7px;
  width: 100%;
  align-items: center;
  display: flex;
  text-decoration: none;
}

.sidebar-options .logout span {
  position: absolute;
  left: 35px;
  top: auto;
  opacity: 0;
  visibility: hidden;
  font-size: 14px;
  line-height: 153%;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: rgb(237, 74, 42);
  white-space: nowrap;
  transition: all 0.2s ease-in-out 0s;
}

.sidebar-options:hover .logout span {
  opacity: 1;
  visibility: visible;
}

.sidebar-options .logout i {
  color: rgb(237, 74, 42);
}

.sidebar-options ul li {
  padding: 8px 10px;
  list-style: none;
  padding-left: 10px;
}

.sidebar-options ul li a {
  text-decoration: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: all 0.2s ease-in-out 0s;
  position: relative;
  padding-left: 15px;
  color: white;
  font-size: 16px;
  gap: 3px;
}

.sidebar-options:hover .sidebar-options ul li a {
  width: 100%;
}

.sidebar-options ul li a i {
  color: white;
}

.sidebar-options ul li a:hover .sidebar-options ul li a i {
  color: rgb(168, 115, 250);
}

.sidebar-options ul li a span {
  position: absolute;
  top: -20px;
  left: calc(100% + 15px);
  z-index: 3;
  background: #fff;
  box-shadow: 0 5px 10px rgb(0 0 0 / 30%);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 400;
  opacity: 0;
  white-space: nowrap;
  pointer-events: none;
  transition: 0s;
}

.sidebar-options ul li:hover .sidebar-options ul li a span {
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
  top: 50%;
  transform: translateY(-50%);
}

.profile-svg {
  background-color: white;
  border-radius: 50%;
  height: 45px;
  width: 45px;
}

.profile-svg svg {
  top: 15%;
  position: absolute;
  transform: translateX(-50%);
}
.sidebar-options button {
  background-color: transparent;
  color: white;
  font-size: 16px;
}

@media (min-height: 700px) {
  .sidebar-options ul li {
    padding: 12px 10px;
  }
}

.ant-tooltip-inner {
  background-color: #0e4d92 !important;
  border-radius: 10px !important;
  z-index: 10000;
  box-shadow: none !important;
  margin-top: 10px;
}

.ant-tooltip-arrow {
  display: none !important;
}

.logo-home {
  margin-left: 110px;
  margin-top: 5px;
}

.ant-tooltip-inner {
  /* margin-top: 50px !important;   */
}

.logout-btn .antd-logout-btn {
  /* margin-top: 10px; */
}

.main-menu .logo-border {
  border: 1px solid white !important;
  background-color: white !important;
  transition: all 0.5s ease !important;
  border-radius: 10px !important;
}
.main-menu .logo-border a {
  padding-left: 0px !important;
  color: #0e4d92 !important;
  transition: all 0.5s ease !important;
}

.main-menu .logo-orignal {
  border: 1px solid transparent !important;
}

.main-menu .logo-orignal a {
  padding-left: 0px !important;
}

.noti-icon-multi {
  position: absolute;
  right: 1%;
  border-radius: 15px;
  cursor: pointer;
}

.ant-notification-notice-description {
  margin: 0px !important;
}

.popup-card .card {
  padding: 10px 20px 10px 20px !important ;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  width: 380px;
}

.popup-card .card .popup-name {
  color: black !important;
}
.popup-card .card .popup-time {
  color: black !important;
  margin-bottom: 5px;
}
.popup-card .card .popup-message {
  color: #0e4d92 !important;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
  margin-bottom: 20px !important;
  margin-left: 48px !important;
  font-size: 20px !important;
  font-weight: 700;
}

.ant-notification-notice {
  width: 450px !important;
}

.ant-notification {
  z-index: 1000 !important;
}

.noti-popup {
  width: 100%;
  max-height: 470px !important;
  overflow-y: scroll !important;
  background-color: white;
  padding-left: 15px;
}

.popup-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px !important;
}

.number {
  position: absolute;
  color: white;
  right: -6px;
  top: -1px;
  z-index: 1;
  border: 1px solid red;
  border-radius: 30px;
  padding-right: 5px;
  padding-left: 5px;
  font-size: 11px;
  background-color: red;
}

.noti-edit {
  position: absolute;
  right: 5px;
  bottom: 6px;
}

.mark-as-read {
  position: absolute;
  right: 5px;
  top: 0px;
}

.notification {
  width: max-content !important;
  top: 24px !important;
}

.notification .ant-modal-header {
  width: 104% !important;
}

.notification .ant-modal-content {
  height: 0px !important;
}

.notification .ant-modal-body {
  width: 104% !important;
  overflow: visible !important;
  padding: 0px !important;
  background: white !important;
  min-height: 140px !important;
  height: max-content !important;
  display: flex;
  justify-content: center;
  align-items:center ;
  flex-direction: column;
}

.notification .ant-modal-content .ant-modal-header {
  background-color: white !important;
}

.noti-title {
  display: flex;
  justify-content: start;
  align-items: center;
}

.noti-title svg {
  margin-right: 15px;
  font-size: 25px;
  color: #1b90ff;
}

.notification .ant-select {
  width: 90%;
  /* padding: 5px 10px; */
  background-color: white;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}

.notification .ant-select-selector {
  border-radius: 10px !important;
  width: 100%;
}

.notification .select-div{
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
}
