
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  outline: none;
}

.wrapper {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  position: relative;
  z-index: 10;
  background-image: url(../../../assets/images/bg12.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.signup-block {
  position: relative;
  /* width: 1122px; */
  margin: 0 auto;
  width: 100%;
  height: 100%;
}
.signup-form-block {
  display: flex;
  max-width: 900px;
  border: 2px solid #b3bac5;
  border-radius: 21px;
  margin: 0 auto;
  height: 550px;
}
.signup-left-block {
  background-color: #0e4d92;
  width: 40%;
  text-align: center;
  border-radius: 21px 0 0 21px;
}
.signup-right-block {
  background-color: white;
  width: 60%;
  padding: 85px;
  text-align: center;
  margin: 0 auto;
  border-radius: 0 21px 21px 0;
}
.sign-text h3 {
  font-size: 28px;
  line-height: 37px;
  /* font-weight: bold; */
  color: #0e4d92;
  margin: 0 auto;
  margin-bottom: 20px;
  float: left;
}
.input input[type="email"],
.input input[type="password"] {
  padding: 7px 10px;
  border-radius: 7px;
  border: 2px solid #b3bac5;
  height: 40px;
  width: 100%;
  margin-bottom: 20px;
}
.form-link {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  margin-top: 10px;
}
.form .signin-btn {
  width: 100%;
  padding: 8px 10px;
  font-size: 18px;
  background-color: #0a9100 !important;
  border: none;
  color: white !important;
  /* font-weight: bold; */
  margin-bottom: 40px;
  height: 40px;
  /* width: 126%; */
  border-radius: 3px;
  cursor: pointer;
  /* display: inline; */
  margin-left: 0px !important;
}

/* .btn-cls .signin-btn {
  width: 126px; 
} */
.or-sign {
  margin-bottom: 20px;
  color: black;
  font-size: 18px;
}
.google-sign-links {
  display: flex;
  justify-content: center;
}
.google-sign-links a {
  border: 1px solid #b3bac5;
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 3px;
}
.google-sign-links a img {
  margin-top: 7px;
}
.forget-link a {
  color: #0d4583;
  /* font-weight: 560; */
  margin-right: 7px;
  font-size: 14px;
  line-height: 19px;
}
.forget-link a .signup-btn-1 button {
  padding: 5px 10px;
  color: white;
  font-size: 15px;
  border: 1px solid white;
  border-radius: 4px;
  background-color: #0e4d92;
  width: 100px;
  font-weight: bold;
  cursor: pointer;
}
.dont-account {
  margin-bottom: 15px;
}
.dont-account a {
  color: white;
  margin-bottom: 20px;
}
.ats-title {
  color: white;
  margin-bottom: 60px;
  text-transform: uppercase;
}
.ats-title p {
  width: 70%;
  float: right;
}
.wise-img {
  margin-top: 80px;
}
.wise-img img {
  width: 200px;
}
.cross-sing i {
  color: black;
  width: 40px;
  height: 40px;
}
.ats-text p {
  color: #ffffff;
  width: 50%;
  float: right;
}
.check-box-rem span {
  font-size: 15px;
  color: #505050;
  margin-left: 6px;
}
.btn-cls {
  display: flex;
  float: left;
}

.btn-cls .cancel-btn {
  margin-left: 10px;
  color: #505050 !important;
  background-color: white !important;
  border: 2px solid #b3bac5;
  width: 126px;
  /* height: 40%; */
  /* border-radius: 3px; */
  padding: 5px 10px;
  /* font-size: 18px; */
}
.sign-text-bold {
  font-weight: bold;
}
.submit-btn {
  width: 126px;
}
.sign-btn-for-login {
  width: 100%;
}



*::selection {
  background-color: #0E4D92 !important;
  color: white !important;
}
.row {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  flex-wrap: nowrap !important;
}

/* section {
  background-image: url("../../../assets/Img/wave.png");
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  filter: hue-rotate(45deg);
} */

.mb-3 {
  width: 60%;
}

.row .col-2 {
  flex: auto !important;
  max-width: fit-content !important;
}

.row .form-info .row {
  font-family: "Albert Sans", sans-serif;
}

.form-info {
  max-width: 500px !important;
}
.row .container .row img {
  width: 25%;
}
.form-info .row {
  display: block;
  justify-content: center;
  text-align: center;
}

.form-info {
  border-radius: 45px;
  box-shadow: -8px 3px 30px #0bc580e8;
  padding: 20px 0;
  width: 50% !important;
  margin-right: 80px !important;
}

.form-control {
  padding: 0.375rem 1.75rem;
}

.container form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  align-content: center;
}

input {
  text-align: center;
}

.row .form-info .btn {
  font-size: 15px;
  width: 50%;
  border: none;
  background-color: #0bc580e8;
  border-radius: 20px;
  box-shadow: 1px 1px 17px 4px #fdfdfde8;
}

.icons {
  font-size: 20px;
  padding-top: 10px;
}

.icons .fa-google {
  color: red;
}
.icons .fa-linkedin {
  color: #075072;
}
.icons .fa-twitter {
  color: aqua;
}

.user-box {
  position: relative;
}

.user-box span {
  position: absolute;
  left: 10px;
  top: 10px;
  /* color: aqua !important; */
}

input:focus + .fa-user,
input:focus + .fa-lock {
  /* color: #04e1ae !important; */
}

/* -----------------media querry-------------------------------- */

@media only screen and (max-width: 768px) {
  .row .form-info .btn {
    font-size: 10px;
  }
  .form-control {
    font-size: 10px;
  }
  .user-box i {
    top: 8px;
  }
  h1 {
    font-size: 25px;
  }
  label {
    font-size: 12px;
  }
  .form-info {
    box-shadow: 1px 1px 17px 4px #fdfdfde8;
  }
}

@media only screen and (max-width: 586px) {
  .row .container .row img {
    width: 30%;
  }
  .form-img img {
    width: 0%;
  }
  .row .col-2 {
    flex-basis: 100%;
  }
}

@media only screen and (max-height: 500px) {
  .row .form-info .btn {
    font-size: 10px;
  }
  .form-control {
    font-size: 10px;
  }

  h1 {
    font-size: 25px;
  }
  label {
    font-size: 12px;
  }

  .form-info {
    padding: 0%;
  }
}

.user-box svg {
  position: relative;
  top: -30px !important;
  left: 10px !important;
}
