.changePassword,
.editForm,
.user-real-modal {
  width: 50% !important;
}

.user-real-modal {
  height: 400px !important;
}

.user-real-modal .ant-modal-content {
  width: 100% !important;
  border-radius: 10px;
}

.user-real-modal .ant-modal-title {
  text-align: center;
}

.user-real-modal .user-form {
  /* padding-left: 25%; */
}

.changePassword .ant-modal-body {
  height: 40% !important;
}

.user-real-modal .ant-form-item-label label {
  margin-bottom: 0px !important;
}

.user-real-modal .ant-form-item {
  margin-bottom: 8px !important;
}

.user-real-modal .form-group {
  margin-bottom: 0px !important;
}

.user-real-modal .ant-modal-body {
  padding: 15px 24px 24px 24px !important;
}

.changePassword .ant-modal-title {
  text-align: center;
}

.changePassword .ant-form-item-label label {
  margin-bottom: 0px !important;
}

.changePassword .ant-form-item {
  margin-bottom: 8px !important;
}

.changePassword .form-group {
  margin-bottom: 0px !important;
}

.editForm .ant-modal-title {
  text-align: center;
}

.editForm .ant-form-item-label label {
  margin-bottom: 0px !important;
}

.editForm .ant-form-item {
  margin-bottom: 8px !important;
}

.editForm .form-group {
  margin-bottom: 0px !important;
}

.editForm .ant-modal-body {
  height: 40% !important;
}

.editForm .ant-input,
.changePassword .ant-input,
.user-real-modal .ant-input {
  text-align: left;
}

.user-modal-div-form .form-row-user {
  display: flex !important;
  justify-content: space-around !important;
}

/* .form-row-user .user-row-div{
    flex-basis: 50% !important;
} */

.form-row-user .input-field {
  width: 250px !important;
}

.form-row-user .input-field:hover {
  border: 1px solid black;
}

.user-row-div .ant-select-selector {
  border: 1px solid #d9d9d9;
}

.user-row-div .ant-select-selector {
  border: 1px solid black;
}

.user-row-div .ant-select.ant-select-in-form-item {
  width: 250px !important;
}

.user-btn-group {
  display: flex !important;
  justify-content: center !important;
  margin-top: 20px !important;
}

.user-form label {
  color: #043c79 !important;
}


.user-form .input-field {
  background-color: #f2f2f2 !important;
  border-radius: 8px !important;
}

.form-row .PhoneInputInput {
  background-color: #f2f2f2 !important;
  border-radius: 8px !important;
  border: 1px solid #e3d8d8 !important;
  padding: 3px;
  text-align: left;
}

.user-form .ant-select-selector {
  background-color: #f2f2f2 !important;
  border-radius: 8px !important;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: black !important;
}