.App {
  height: 100vh;
  width: 100vw;
}
body {
  margin: 0;
  padding: 0;
  background-color: white;
}
.sidebar {
  height: 100%;
  /* width: 150px; */
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #5e585b;
  transition: 0.5s;
  overflow-x: hidden;
  /* padding-top: 15px; */
  white-space: nowrap;
}

.sidebar-list-1 {
  height: auto;
  padding: 0;
  width: 100%;
}
.sidebar-list-1 .row-1 {
  width: 100%;
  height: 50px;
  margin: 0;
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: center;
  align-items: center;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.sidebar-list-1 .row-1:hover {
  cursor: pointer;
  background-color: #000000;
}

.sidebar-list-1 #active {
  background-color: #000000;
}

.row-1 #icon {
  flex: 30%;
  display: grid;
  place-items: center;
}
.row-1 #title {
  flex: 70%;
}
.row-1 a {
  display: flex;
  align-items: center;
  color: white;
  flex: 100%;
}



.inc-out .ant-tooltip-open{
  margin-top: 20px !important;
}